import {
  AppBar,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DeviceModel } from './Devices';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../context/UserStateManager';
import moment from 'moment/moment';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { LogType, LogTypeMap } from '../Enums';

type LogDto = {
  time: string;
  source: string;
  type: string;
  description: string;
  tokenIssued: string;
  revokeCode: string;
  tokenLifespan: string;
  showChanges: [];
}[];

type LogData = {
  index: number;
  time: moment.Moment;
  source: string;
  type: string;
  description: string;
  tokenIssued: string;
  revokeCode: string;
  tokenLifespan: string;
  showChanges: string;
}[];

type props = {
  deviceData: null | DeviceModel;
  closeDialog: Function;
  logType: LogType | null;
};

export function DeviceLog({ deviceData, closeDialog, logType }: props) {
  const userCon = useContext(UserContext);
  const listener = useRef<NodeJS.Timeout | null>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [pageSize, setPageSize] = useState(25);
  const [deviceLog, setDeviceLog] = useState<LogData>([]);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [col, setCol] = useState<GridColDef[]>([]);
  const [mostRecentTime, setMostRecentTime] = useState<Date>();

  const columns: GridColDef[] = [
    {
      field: 'time',
      headerName: 'Time',
      width: 200,
      filterable: true,
      disableColumnMenu: true,
      valueGetter: (params, row) =>
        moment(row.time)
          .tz(deviceData?.timeZone ?? '')
          .format('L LTS'),
    },
    {
      field: 'source',
      headerName: 'Source',
      filterable: true,
      disableColumnMenu: true,
      width: 120,
    },
    {
      field: 'type',
      headerName: 'Type',
      filterable: true,
      width: 120,
    },
    {
      field: 'description',
      headerName: 'Message',
      filterable: true,
      width: 600,
    },
  ];

  const settingsColumns: GridColDef[] = [
    {
      field: 'time',
      headerName: 'Time',
      width: 200,
      filterable: true,
      disableColumnMenu: true,
      valueGetter: (params, row) =>
        moment(row.time)
          .tz(deviceData?.timeZone ?? '')
          .format('L LTS'),
    },
  ];

  const ccColumns = [
    {
      field: 'tokenIssued',
      headerName: 'Time',
      width: 200,
      filterable: true,
      disableColumnMenu: true,
      valueGetter: (params: any) =>
        moment(params.row.tokenIssued)
          .tz(deviceData?.timeZone ?? '')
          .format('L LTS'),
    },
    {
      field: 'revokeCode',
      headerName: 'Revoke Code',
      filterable: true,
      disableColumnMenu: true,
      width: 150,
    },
    {
      field: 'tokenLifespan',
      headerName: 'Token Duration (s)',
      filterable: true,
      width: 200,
    },
    {
      field: 'showChanges',
      headerName: 'Show Count Change',
      filterable: true,
      width: 600,
    },
  ];

  useEffect(() => {
    if (logType === LogType.SETTINGS) {
      setCol(settingsColumns);
    } else if (logType === LogType.CCEVENT) {
      setCol(ccColumns);
    } else {
      setCol(columns);
    }

    fetchLogs();

    return () => {
      if (listener.current) clearInterval(listener.current);
      if (timeout.current) clearInterval(timeout.current);
    };
  }, []);

  useEffect(() => {
    fetchLogs();
  }, [pageSize]);

  const fetchLogs = useCallback(
    (newPage = 0, automatic = false, updatedPageSize = pageSize) => {
      setLoading(true);

      let url = '';
      if (logType === LogType.DEBUG) {
        const url =
          '/devices/new-debug/' +
          deviceData?._id +
          `/${updatedPageSize}/${newPage}` +
          (automatic ? (mostRecentTime ? `/${mostRecentTime}` : '') : '');

        console.log(url);
        fetchLogoutOn401(userCon, process.env.REACT_APP_BACKEND_URL + url, {
          headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
        })
          .then((res) => {
            if (res.ok) return res.json();
            else {
              setLoading(false);
              throw Error;
            }
          })
          .then((json) => {
            if (json.totalData.length < 1) {
              return;
            }
            if (rowCount === 0 && json.totalCount) {
              setRowCount(json.totalCount);
            } else if (automatic && mostRecentTime && json.totalCount) {
              setRowCount(rowCount + json.totalCount);
            }
            setMostRecentTime(json.totalData[0].time);
            setDeviceLog(
              (json.totalData as LogDto).map((obj, i) => ({
                index: i,
                time: moment(obj.time),
                source: obj.source,
                type: obj.type,
                description: obj.description,
                tokenIssued: obj.tokenIssued,
                revokeCode: obj.revokeCode,
                tokenLifespan: obj.tokenLifespan,
                showChanges:
                  obj.showChanges != null
                    ? obj.showChanges.length.toString()
                    : '',
              })),
            );
          })
          .then(() => {
            setLoading(false);
          });
      } else {
        if (logType === LogType.STATUS) {
          url = '/devices/errors/' + deviceData?._id;
        } else {
          url = '/devices/logs/' + logType + '/' + deviceData?._id;
        }
        fetchLogoutOn401(userCon, process.env.REACT_APP_BACKEND_URL + url, {
          headers: { Authorization: 'Bearer ' + userCon.state.jwtToken },
        })
          .then((res) => {
            if (res.ok) return res.json();
            else {
              setLoading(false);
              throw Error;
            }
          })
          .then((json) =>
            setDeviceLog(
              (json as LogDto)
                .map((obj, i) => ({
                  index: i,
                  time: moment(obj.time),
                  source: obj.source,
                  type: obj.type,
                  description: obj.description,
                  tokenIssued: obj.tokenIssued,
                  revokeCode: obj.revokeCode,
                  tokenLifespan: obj.tokenLifespan,
                  showChanges:
                    obj.showChanges != null
                      ? obj.showChanges.length.toString()
                      : '',
                }))
                .reverse(),
            ),
          )
          .then(() => {
            setPageSize(deviceLog.length);
            setLoading(false);
          });
      }
    },
    [deviceData?._id, deviceLog.length, logType, pageSize, rowCount, userCon],
  );

  const [checked, setChecked] = useState(false);
  const [count, setCount] = useState(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // Start the interval when the switch is checked
      timerRef.current = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount === 4) {
            fetchLogs(0, true);
            return 0;
          }
          return prevCount + 1;
        });
      }, 1000);
    } else {
      // Clear the interval when the switch is unchecked
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
      setCount(0);
    }

    setChecked(event.target.checked);
  };

  return (
    <div style={{ minHeight: '100%', backgroundColor: '#F4F7FC' }}>
      <AppBar
        color="secondary"
        sx={{ height: `68px`, borderBottom: '1px solid #e0e0e0' }}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            aria-label="go back"
            edge="start"
            onClick={() => closeDialog()}
          >
            <ArrowBackIcon style={{ fontSize: '3rem', color: 'black' }} />
          </IconButton>
          <Typography variant="h1">
            {deviceData?.deviceID} {LogTypeMap[logType!]} Log
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ height: '68px' }} />

      <Paper style={{ height: '900px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography>
            {`Times in controller timezone: ${
              deviceData?.timeZone
                ? moment().tz(deviceData.timeZone)?.format('z')
                : 'Unknown'
            }`}
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={handleSwitchChange}
                  aria-label="Switch"
                  color="primary"
                />
              }
              label={<span style={{ color: 'black' }}>Automatic Refresh</span>}
              labelPlacement="start"
              style={{
                marginBottom: '0',
              }}
            />
            <Typography
              style={{
                fontSize: '0.75rem',
                fontStyle: 'italic',
                visibility: checked ? 'visible' : 'hidden',
              }}
            >
              {count === 0
                ? 'Refreshing...'
                : `Refreshing logs in ${5 - count} seconds`}
            </Typography>
          </div>
        </div>
        {logType === LogType.DEBUG ? (
          <DataGrid
            loading={loading}
            sx={{ height: '89%' }}
            initialState={{
              pagination: { paginationModel: { pageSize: pageSize } },
            }}
            onPaginationModelChange={(newSize) => {
              fetchLogs(newSize.page);
              setPageSize(newSize.pageSize);
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            density="compact"
            rows={deviceLog}
            pagination={true}
            paginationMode="server"
            rowCount={rowCount}
            columns={col}
            getRowId={(row) => row.index}
            ref={(n) => {
              tableRef.current = n;
            }}
          />
        ) : (
          <DataGrid
            loading={loading}
            sx={{ marginTop: '8px', height: '89%' }}
            initialState={{
              pagination: { paginationModel: { pageSize: pageSize } },
            }}
            onPaginationModelChange={(newSize) => setPageSize(newSize.pageSize)}
            pageSizeOptions={[10, 25, 50, 100]}
            density="compact"
            rows={deviceLog}
            columns={col}
            getRowId={(row) => row.index}
            ref={(n) => {
              tableRef.current = n;
            }}
          />
        )}
      </Paper>
    </div>
  );
}

export default DeviceLog;
