import {
  AppBar,
  Button,
  FormControlLabel,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HighlightIcon from '@mui/icons-material/Highlight';
import CheckIcon from '@mui/icons-material/Check';
import { useHistory } from 'react-router-dom';
import { Autocomplete } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import { useContext, useState } from 'react';
import { Group, GroupTreeNode } from './orgTypes';
import { DeviceModel } from '../devices/Devices';
import {
  buildAllPaths,
  createGroupFormSubmit,
  deleteGroup,
  findGroupPathByName,
  findParentGroupIdByPath,
  updateGroupFormSubmit,
} from './groupsHelpers';
import { useMutation, useQueryClient } from 'react-query';
import { UserContext } from '../context/UserStateManager';
import { toast, ToastContainer } from 'react-toastify';
import ConfirmationModal from '../common/ConfirmationModal';

interface OrgGroupsAddFormProps {
  group?: Group;
  groupTree: GroupTreeNode[];
  ungroupedDevices: DeviceModel[];
  closeDialog: () => void;
  editPath?: string;
}

export default function OrgGroupsEditForm({
  group,
  ungroupedDevices,
  groupTree,
  closeDialog,
  editPath,
}: OrgGroupsAddFormProps) {
  const userCon = useContext(UserContext);
  const theme = useTheme();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('type');
  const [page, setPage] = useState(0);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const paths = buildAllPaths(groupTree);
  const queryClient = useQueryClient();

  const [deviceList, setDeviceList] = useState<DeviceModel[]>(
    group?.devices || [],
  );
  const [deleteDevices, setDeleteDevices] = useState<DeviceModel[]>([]);
  const { control, handleSubmit, setValue } = useForm({});

  const handleRequestSort = (property: any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = deviceList.slice().sort((a, b) => {
    return order === 'asc'
      ? a.name.localeCompare(b.name)
      : b.name.localeCompare(a.name);
  });

  const history = useHistory();

  const filteredPaths = group
    ? paths
        .filter((path) => !path.includes(group.name))
        .filter((path) => !(path.split('/').length > 3))
    : paths.filter((path) => !(path.split('/').length > 3));

  const handleToggleDeleteDevice = (device: DeviceModel) => {
    const isDeleted = deleteDevices.some((d) => d.name === device.name);
    if (isDeleted) {
      setDeleteDevices(deleteDevices.filter((d) => d.name !== device.name));
    } else {
      setDeleteDevices([...deleteDevices, device]);
    }
  };

  const isDeviceDeleted = (device: DeviceModel) => {
    return deleteDevices.some((d) => d.name === device.name);
  };

  const { mutate: mutateGroup } = useMutation(
    'updateGroup',
    async (variables: {
      groupId: string;
      name: string;
      parentGroup: string;
      newDevices: DeviceModel[];
      deleteDevices: DeviceModel[];
    }) =>
      await updateGroupFormSubmit(
        userCon,
        variables.groupId,
        variables.name,
        variables.parentGroup,
        variables.newDevices,
        variables.deleteDevices,
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get-groups');
        closeDialog();
      },
      onError: (error) => {
        toast.error(String(error));
      },
    },
  );
  const { mutate: createGroup } = useMutation(
    'createGroup',
    async (variables: {
      name: string;
      parentGroup: string;
      newDevices: DeviceModel[];
      org: string;
    }) =>
      await createGroupFormSubmit(
        userCon,
        variables.name,
        variables.parentGroup,
        variables.newDevices,
        variables.org,
      ),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get-groups');
        closeDialog();
      },
      onError: (error) => {
        toast.error(String(error));
      },
    },
  );

  const { mutate: deleteGroupMutation } = useMutation(
    'deleteGroup',
    async () => await deleteGroup(userCon, group?._id || ''),
    {
      onSuccess: () => {
        queryClient.refetchQueries('get-groups');
        closeDialog();
      },
      onError: (error) => {
        toast.error(String(error));
      },
    },
  );

  const onSubmit = (data: any) => {
    if (group) {
      const formData = {
        groupId: group?._id,
        ...data,
        deleteDevices: deleteDevices,
        parentGroup: findParentGroupIdByPath(
          !data.parentGroup ? '' : data.parentGroup,
          groupTree,
        ),
      };
      mutateGroup(formData);
    } else {
      const formData = {
        ...data,
        parentGroup: findParentGroupIdByPath(
          !data.parentGroup ? '' : data.parentGroup,
          groupTree,
        ),
        org: userCon.state.currentOrg?.org._id,
      };

      createGroup(formData);
    }
  };

  const defaultPath = editPath || findGroupPathByName(paths, group?.name || '');

  return (
    <>
      <form style={{ minHeight: '100%', backgroundColor: '#F4F7FC' }}>
        <AppBar
          color="secondary"
          sx={{ height: `68px`, borderBottom: '1px solid #e0e0e0' }}
          position="fixed"
          elevation={0}
        >
          <Toolbar>
            <IconButton aria-label="go back" edge="start" onClick={closeDialog}>
              <ArrowBackIcon style={{ fontSize: '3rem', color: 'black' }} />
            </IconButton>
            <Typography variant="h1">
              {!group ? 'Create New Group' : 'Edit Group'}
            </Typography>
          </Toolbar>
        </AppBar>

        <div style={{ height: '68px' }} />
        <div
          style={{
            width: '100%',
            marginTop: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
              width: '95%',
            }}
          >
            <div
              style={{
                width: '600px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Paper sx={{ marginBottom: theme.spacing(2), width: '92%' }}>
                <Typography variant="h3" style={{ marginBottom: '16px' }}>
                  Basic Details
                </Typography>

                <Controller
                  name="name"
                  defaultValue={group?.name || ''}
                  control={control}
                  rules={{
                    required: 'Name is required',
                    pattern: {
                      value: /^[a-zA-Z0-9 _]+$/,
                      message:
                        'Only letters, numbers, and underscores are allowed.',
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControlLabel
                      label="Name"
                      labelPlacement="start"
                      control={
                        <TextField
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          placeholder="Group Name"
                          style={{ width: '275px' }}
                        />
                      }
                    />
                  )}
                />

                <FormControlLabel
                  label="Subdirectory"
                  labelPlacement="start"
                  control={
                    <Controller
                      name="parentGroup"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          defaultValue={defaultPath}
                          style={{ minWidth: '200px', maxWidth: '300px' }}
                          options={filteredPaths}
                          getOptionLabel={(option) =>
                            option.split('/').join(' / ')
                          }
                          onChange={(event, newValue) => {
                            setValue('parentGroup', newValue || '');
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Subdirectory"
                            />
                          )}
                        />
                      )}
                    />
                  }
                />

                <div
                  style={{
                    maxWidth: '650px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="h3"
                    style={{
                      marginTop: '20px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      width: '100%',
                    }}
                  >
                    Devices
                  </Typography>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell width={1}></TableCell>
                          <TableCell colSpan={6}>
                            <TableSortLabel
                              active={orderBy === 'name'}
                              direction={
                                orderBy === 'name' && order === 'desc'
                                  ? 'desc'
                                  : 'asc'
                              }
                              onClick={() => handleRequestSort('name')}
                            >
                              Name
                            </TableSortLabel>
                          </TableCell>
                          <TableCell width={1}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortedData
                          .slice(page * 5, page * 5 + 5)
                          .map((item, key) => (
                            <TableRow key={key}>
                              <TableCell width={1} style={{ padding: '2px' }}>
                                <Icon
                                  color="primary"
                                  style={{
                                    paddingLeft: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <HighlightIcon />
                                </Icon>
                              </TableCell>

                              <TableCell
                                style={{
                                  cursor: 'pointer',
                                  color: isDeviceDeleted(item)
                                    ? 'red'
                                    : 'inherit',
                                }}
                                colSpan={6}
                                onClick={() => {
                                  userCon.setState({
                                    ...userCon.state,
                                    currentDevice: item,
                                  });
                                  history.push('/controller/' + item._id);
                                }}
                              >
                                {item.name}
                              </TableCell>

                              <TableCell width={1} style={{ padding: '0' }}>
                                <IconButton
                                  onClick={() => handleToggleDeleteDevice(item)}
                                >
                                  {isDeviceDeleted(item) ? (
                                    <CheckIcon style={{ color: 'green' }} />
                                  ) : (
                                    <DeleteOutlineIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '20px',
                        }}
                      >
                        <FormControlLabel
                          label=""
                          labelPlacement="start"
                          control={
                            <Controller
                              name="newDevices"
                              control={control}
                              render={({ field }) => (
                                <Autocomplete
                                  {...field}
                                  disableCloseOnSelect
                                  multiple
                                  style={{ width: '200px' }}
                                  options={ungroupedDevices}
                                  getOptionLabel={(device) => device.name}
                                  onChange={(event, newValue) =>
                                    setValue('newDevices', newValue)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      label="Add Device..."
                                    />
                                  )}
                                />
                              )}
                            />
                          }
                        />
                      </div>
                      <TablePagination
                        style={{ marginTop: '18px' }}
                        rowsPerPageOptions={[5]}
                        component="div"
                        count={deviceList.length}
                        rowsPerPage={5}
                        page={page}
                        onPageChange={(event, newPage) => {
                          setPage(newPage);
                        }}
                      />
                    </div>
                  </TableContainer>
                </div>
              </Paper>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginTop: theme.spacing(3),
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'flex',
              }}
              onClick={handleSubmit(onSubmit)}
            >
              {!group ? 'Create New Group' : 'Apply Group Edits'}
            </Button>

            {group && (
              <Button
                variant="contained"
                style={{ backgroundColor: 'red', color: 'white' }}
                onClick={(e) => {
                  e.preventDefault();
                  setDeleteModalOpen(true);
                }}
                sx={{
                  marginTop: theme.spacing(3),
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'flex',
                }}
              >
                Delete Group
              </Button>
            )}
          </div>

          <div style={{ height: '20px' }} />
        </div>
      </form>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <ConfirmationModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={() => {
          deleteGroupMutation();
        }}
        title={'Delete Group?'}
        message="Are you sure you want to delete this group from your organization?"
        confirmText="Delete"
      />
    </>
  );
}
