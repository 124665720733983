import {
  AppBar,
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete } from '@mui/lab';
import { GroupEvents } from '../orgs/orgTypes';
import { useContext } from 'react';
import { UserContext } from '../context/UserStateManager';
import ShowLineupList from '../configuration/ShowLineupList';
import { getGroupNameById } from './groupsHelpers';

type props = {
  closeDialog: () => void;
  eventData: null | GroupEvents;
  submitEvent: (event: GroupEvents) => void;
};

export default function GroupEventsDialogForm({
  closeDialog,
  eventData,
  submitEvent,
}: props) {
  const userCon = useContext(UserContext);
  const theme = useTheme();
  const { control, handleSubmit, getValues } = useForm<GroupEvents>({
    defaultValues: eventData
      ? {
          ...eventData,
          startDate: moment(eventData.startDate + '/' + moment().year()).format(
            'MM/DD/YYYY',
          ),
          endDate: moment(eventData.endDate + '/' + moment().year()).format(
            'MM/DD/YYYY',
          ),

          groups: eventData.groups.map(
            (val) =>
              (userCon.state.currentOrgGroupPaths || []).find((ele) =>
                ele.endsWith(
                  getGroupNameById(
                    val,
                    userCon.state.currentOrgGroupTree || [],
                  ) || '',
                ),
              ) || '',
          ),
        }
      : {
          name: '',
          startDate: moment().format('MM/DD/YYYY'),
          endDate: moment().format('MM/DD/YYYY'),
          shows: [],
        },
  });

  let paths = userCon.state.currentOrgGroupPaths ?? [];
  paths = paths.slice(0, paths.length - 1);

  const validateDateRange = () => {
    const startDate = getValues('startDate');
    const endDate = getValues('endDate');

    if (startDate && endDate) {
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      if (startDateObj > endDateObj) {
        return false;
      }
      return true;
    }
  };

  return (
    <div style={{ minHeight: '100%', backgroundColor: '#F4F7FC' }}>
      <AppBar
        color="secondary"
        sx={{ height: `68px`, borderBottom: '1px solid #e0e0e0' }}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            aria-label="go back"
            edge="start"
            onClick={() => {
              closeDialog();
            }}
          >
            <ArrowBackIcon style={{ fontSize: '3rem', color: 'black' }} />
          </IconButton>
          <Typography variant="h1">
            {eventData ? 'Edit Event' : 'Add Event'}
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ height: '68px' }} />
      <div
        style={{
          margin: 'auto',
          marginTop: theme.spacing(2),
          maxWidth: `min(500px, calc(100% - ${
            parseInt(theme.spacing(2)) * 2
          }px))`,
        }}
      >
        <form
          id="event-form"
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          onSubmit={handleSubmit((event) => {
            submitEvent({
              ...event,
              startDate: moment(event.startDate).format('MM/DD'),
              endDate: moment(event.endDate).format('MM/DD'),
            });
            closeDialog();
          })}
        >
          <Paper sx={{ marginBottom: theme.spacing(2) }}>
            <Typography variant="h2">Event Information</Typography>
            <Controller
              name="name"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="Name:"
                  labelPlacement="start"
                  style={{ maxWidth: '230px' }}
                  control={
                    <TextField
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  }
                />
              )}
              rules={{
                required: 'An event name is required',
                maxLength: {
                  value: 50,
                  message: 'Must be less than 50 characters',
                },
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Controller
                name="startDate"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="flex flex-col gap-y-2">
                    <DatePicker
                      label="Start Date"
                      openTo="month"
                      views={['month', 'day']}
                      value={moment(value)}
                      onChange={(e) => {
                        onChange(e?.format('MM/DD/YYYY'));
                      }}
                    />
                    {error?.message && (
                      <Typography variant={'body2'} color={'error'}>
                        {error?.message}
                      </Typography>
                    )}
                  </div>
                )}
                rules={{
                  validate: () =>
                    validateDateRange() || 'Start Date is after End Date',
                }}
              />
              <div style={{ width: '30px' }} />
              <Controller
                name="endDate"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="flex flex-col gap-y-2">
                    <DatePicker
                      label="End Date"
                      openTo="month"
                      views={['month', 'day']}
                      value={moment(value)}
                      onChange={(e) => {
                        onChange(e?.format('MM/DD/YYYY'));
                      }}
                    />
                    {error?.message && (
                      <Typography variant={'body2'} color={'error'}>
                        {error?.message}
                      </Typography>
                    )}
                  </div>
                )}
                rules={{
                  validate: () =>
                    validateDateRange() || 'End Date is before Start Date',
                }}
              />
            </div>

            <Controller
              name="groups"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  style={{ width: '80%', marginTop: '10px' }}
                  multiple={true}
                  options={paths.map((ele) => ele.split('/').join(' / '))}
                  value={value}
                  onChange={(event, val) => onChange(val)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Event Groups"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Paper>
          <Controller
            name="shows"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <ShowLineupList
                  showList={value}
                  setShows={onChange}
                  eventDialog
                />
                {!!error && (
                  <Typography
                    style={{
                      fontSize: '16px',
                      textAlign: 'center',
                      marginTop: '16px',
                    }}
                    variant="caption"
                    color="error"
                  >
                    {error.message}
                  </Typography>
                )}
              </>
            )}
            rules={{
              validate: {
                isAfter: (shows) =>
                  shows.length > 0 || 'At least one show must be selected',
              },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form="event-form"
            sx={{
              marginTop: theme.spacing(3),
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            Submit Event
          </Button>
        </form>
      </div>
    </div>
  );
}
