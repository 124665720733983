import { fetchLogoutOn401 } from "../common/Handle401Fetch";
import { tUserContext } from "../context/UserStateManager";

export async function getCurrentShow(userCon: tUserContext) {
  if (userCon.state.currentDevice) {
    try {
      const res = await fetchLogoutOn401(
        userCon,
        process.env.REACT_APP_BACKEND_URL +
          '/devices/' +
          userCon.state.currentDevice._id +
          '/currentShow',
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + userCon.state.jwtToken,
          },
        },
      );

      if (res.ok) {
        // Parse the JSON data from the response
        const data = await res.json();
        return data; // Return the parsed data to be used in the component
      } else {
        // Handle non-200 responses
        const errorText = await res.text(); // Get text description of the error
        throw new Error(
          `Request failed with status ${res.status}: ${errorText}`,
        );
      }
    } catch (error) {
      console.error('Error fetching current show:', error);
      throw new Error('Request Unsuccessful');
    }
  }
  throw new Error('No current device found in user context');
}