import { useMutation } from 'react-query';
import { findParentGroupIdByPath, getGroupNameById } from './groupsHelpers';
import { refreshUserContext, UserContext } from '../context/UserStateManager';
import { toast } from 'react-toastify';
import {
  Button,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import { useState, useContext } from 'react';
import { DEFAULT_DEVICE_TYPE_WORDS_NORMAL } from '../../constants';
import { DeviceSettingsContext } from '../configuration/DevicePage';
import { GroupEvents } from './orgTypes';
import GroupEventsDialogForm from './GroupEventsDialogForm';
import { addOrgEvent, deleteOrgEvent, updateOrgEvent } from './helpers';

interface GroupEventsListProps {
  events: GroupEvents[];
}

const EVENT_NUMBER_LIMIT = 365;

export default function GroupEventsList({ events }: GroupEventsListProps) {
  const userCon = useContext(UserContext);
  const theme = useTheme();
  const { updating } = useContext(DeviceSettingsContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogState, setDialogState] = useState({
    open: false,
    eventData: null as null | GroupEvents,
    row: -1,
  });
  const closeDialog = () =>
    setDialogState({ open: false, eventData: null, row: -1 });

  events.sort((first, second) => {
    const startDateComparison = first.startDate.localeCompare(second.startDate);
    if (startDateComparison === 0) {
      return first.endDate.localeCompare(second.endDate);
    }
    return startDateComparison;
  });

  const { mutateAsync: createOrgEventMutation } = useMutation(
    (event: GroupEvents) => addOrgEvent(userCon, event),
    {
      onSuccess: () => {
        refreshUserContext(userCon.state, userCon.setState);
        toast.success('Created Event!');
      },
      onError: (e) => {
        console.log(e);
      },
    },
  );

  const { mutateAsync: updateOrgEventMutation } = useMutation(
    (event: GroupEvents) => updateOrgEvent(userCon, event),
    {
      onSuccess: () => {
        refreshUserContext(userCon.state, userCon.setState);
        toast.success('Updated Event!');
      },
    },
  );

  const { mutateAsync: deleteOrgEventMutation } = useMutation(
    (eventId: string) => deleteOrgEvent(userCon, eventId),
    {
      onSuccess: () => {
        refreshUserContext(userCon.state, userCon.setState);
        toast.success('Deleted Event!');
      },
    },
  );

  const matches = useMediaQuery('(min-width: 500px)');

  return (
    <div>
      <Paper
        sx={{
          display: 'grid',
          gridDirection: 'column',
          padding: theme.spacing(2),
        }}
      >
        <Typography variant="h3" style={{ marginBottom: '8px' }}>
          Group Event Schedule
        </Typography>

        <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>
          Add events, which replaces{' '}
          {DEFAULT_DEVICE_TYPE_WORDS_NORMAL.toLocaleLowerCase()} for the date
          range.
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell style={{ display: matches ? 'table-cell' : 'none' }}>
                Groups
              </TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event, i) => (
              <TableRow key={i}>
                <TableCell width={1}>
                  <IconButton
                    disabled={updating}
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget);
                      setDialogState({
                        ...dialogState,
                        eventData: event,
                        row: i,
                      });
                    }}
                    style={{ padding: 0 }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
                <TableCell>{event.name}</TableCell>
                <TableCell style={{ display: matches ? 'table-cell' : 'none' }}>
                  {event.groups.map(
                    (group, index) =>
                      ((userCon.state.currentOrgGroupPaths || []).find((ele) =>
                        ele.endsWith(
                          getGroupNameById(
                            group,
                            userCon.state.currentOrgGroupTree || [],
                          ) || '',
                        ),
                      ) || '') +
                      (index + 1 !== event.groups.length ? ', ' : ''),
                  )}
                </TableCell>
                <TableCell>
                  {moment(event.startDate, 'MM/DD').format('MMMM Do')}
                </TableCell>
                <TableCell>
                  {moment(event.endDate, 'MM/DD').format('MMMM Do')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setDialogState({ ...dialogState, open: true });
            }}
          >
            <Typography>Edit</Typography>
          </MenuItem>
          <MenuItem
            color="error"
            onClick={() => {
              setAnchorEl(null);
              const eve = events.find((_, i) => i === dialogState.row);
              if (eve) {
                deleteOrgEventMutation(eve._id);
              } else {
                toast.error('Error deleting!');
              }
            }}
          >
            <Typography color="error">Delete</Typography>
          </MenuItem>
        </Menu>
        {events.length < EVENT_NUMBER_LIMIT && (
          <Button
            disabled={updating}
            variant="contained"
            color="primary"
            style={{ margin: '32px auto' }}
            onClick={() =>
              setDialogState({ open: true, eventData: null, row: -1 })
            }
          >
            Add an Event
          </Button>
        )}
        <Dialog fullScreen open={dialogState.open} onClose={closeDialog}>
          <GroupEventsDialogForm
            closeDialog={closeDialog}
            eventData={dialogState.eventData}
            submitEvent={async (event) => {
              const sendEvent = {
                ...event,
                groups:
                  event.groups &&
                  event.groups.map(
                    (ele) =>
                      findParentGroupIdByPath(
                        ele,
                        userCon.state.currentOrgGroupTree ?? [],
                      ) || '',
                  ),
              };

              if (dialogState.eventData) {
                await updateOrgEventMutation(sendEvent);
              } else {
                await createOrgEventMutation(sendEvent);
              }
            }}
          />
        </Dialog>
      </Paper>
    </div>
  );
}
