import {
  Button,
  FormControlLabel,
  Modal,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { UserContext } from '../context/UserStateManager';
import { LineupType } from '../Enums';

type props = {
  lineupType: string;
  open: boolean;
  lineup: any[];
  onClose: Function;
};

export default function SaveLineupModal({
  lineupType,
  open,
  lineup,
  onClose,
}: props) {
  const theme = useTheme();
  const userCon = useContext(UserContext);
  const [saved, setSaved] = useState(false);

  const [lineupUrl, setLineupUrl] = useState('');

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: { name: '' },
  });

  useEffect(() => {
    setLineupUrl(
      lineupType === LineupType.MOOD_LIGHTING ? 'lineup' : 'musiclineup',
    );
  }, [lineupType]);

  const modalClose = () => {
    setSaved(false);
    setValue('name', '');
    onClose();
  };

  return (
    <Modal sx={{ margin: theme.spacing(2) }} open={open} onClose={modalClose}>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: '20vh auto',
          width: 'fit-content',
          minWidth: '250px',
        }}
      >
        <Typography variant="h2">Save Show Lineup</Typography>
        {!saved && (
          <form
            onSubmit={(e) => {
              e.stopPropagation();
              handleSubmit(({ name }) => {
                fetchLogoutOn401(
                  userCon,
                  `${process.env.REACT_APP_BACKEND_URL}/orgs/${userCon.state.currentOrg?.org._id}/${lineupUrl}`,
                  {
                    method: 'POST',
                    headers: {
                      'Content-type': 'application/json',
                      Authorization: 'Bearer ' + userCon.state.jwtToken,
                    },
                    body: JSON.stringify({ name, lineup }),
                  },
                ).then((res) => {
                  if (res.ok) {
                    setSaved(true);
                  }
                });
              })(e);
            }}
          >
            <Controller
              name="name"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="Save as:"
                  labelPlacement="start"
                  control={
                    <TextField
                      value={value}
                      onChange={onChange}
                      style={{ marginLeft: '8px' }}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  }
                />
              )}
              rules={{
                required: 'Name Required',
                maxLength: {
                  value: 50,
                  message: 'Cannot exceed 50 characters.',
                },
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 8,
              }}
            >
              <Button
                variant="text"
                color="secondary"
                style={{ marginRight: '16px', color: '#818387' }}
                onClick={modalClose}
              >
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </div>
          </form>
        )}
        {saved && (
          <>
            <Typography variant="subtitle2">
              Your lineup has been saved.
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 8,
              }}
            >
              <Button variant="contained" color="primary" onClick={modalClose}>
                Close
              </Button>
            </div>
          </>
        )}
      </Paper>
    </Modal>
  );
}
