import {
  Button,
  Dialog,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useContext, useState } from 'react';
import { refreshUserContext, UserContext } from '../context/UserStateManager';
import {
  defaultShowConfiguration,
  ShowConfiguration,
} from '../devices/DeviceSettingsObject';
import { OrgRole, ShowNameMap, SysRole, LineupType } from '../Enums';
import { DeviceSettingsContext } from './DevicePage';
import LoadLineupModal from './LoadLineupModal';
import SaveLineupModal from './SaveLineupModal';
import ShowDialog from './ShowDialog';
import {
  DEFAULT_DEVICE_TYPE_WORDS_NORMAL,
  DEVICE_TYPE_CHECK,
  OTHER_DEVICE_TYPE_WORDS_NORMAL,
} from '../../constants';
import { textColorFromBg } from '../orgs/helpers';
import { OrgColorContext } from '../context/OrgContext';
import { getSystemLineups } from '../admin/SystemLineupList';
import { useQuery } from 'react-query';

type props = {
  showList: ShowConfiguration[];
  setShows: (showList: ShowConfiguration[]) => void;
  zone?: number;
  setZone?: (zoneNum: number) => void;
  eventDialog?: boolean;
  orgDefault?: boolean;
  disabled?: boolean;
  saveDisabled?: boolean;
};

const SHOW_NUMBER_LIMIT = 10;

export default function ShowLineupList({
  showList,
  setShows,
  zone,
  setZone,
  eventDialog,
  orgDefault,
  disabled,
  saveDisabled,
}: props) {
  const userCon = useContext(UserContext);
  const { configuration, updating } = useContext(DeviceSettingsContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogState, setDialogState] = useState({
    open: false,
    showData: null as null | ShowConfiguration,
    row: -1,
  });
  const [showSaveLineup, setShowSaveLineup] = useState(false);
  const [showLoadLineup, setShowLoadLineup] = useState(false);
  const { mapColorValues } = useContext(OrgColorContext);

  const closeDialog = () =>
    setDialogState({ open: false, showData: null, row: -1 });

  const systemLineups = useQuery('system-lineups', () =>
    getSystemLineups(userCon),
  );
  return (
    <div>
      <Paper
        sx={{ display: 'flex', flexDirection: 'column', paddingBottom: 0 }}
      >
        <Typography variant="h3" style={{ marginBottom: '8px' }}>
          {orgDefault && 'Default '}Show Lineup
        </Typography>
        {typeof setZone !== 'undefined' && !eventDialog && !orgDefault && (
          <div style={{ margin: '0' }}>
            <Typography variant="subtitle1">
              Add new shows to create a lineup, or leave this empty to follow
              the organization default lineup.
            </Typography>
            <FormControlLabel
              label={
                configuration.controllerType === DEVICE_TYPE_CHECK
                  ? DEFAULT_DEVICE_TYPE_WORDS_NORMAL + ' Zone:'
                  : OTHER_DEVICE_TYPE_WORDS_NORMAL + ' Zone'
              }
              labelPlacement="start"
              style={{ maxWidth: '400px' }}
              control={
                <Select
                  disabled={updating}
                  value={zone}
                  onChange={(e) => setZone(e.target.value as number)}
                >
                  {Array.isArray(configuration.settings.zones) &&
                  configuration.settings.zones[0].name ? (
                    configuration.settings.zones.map((zone, index) => (
                      <MenuItem key={index} value={index}>
                        {zone.name ?? 'Missing Name'}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem key={0} value={0}>
                      No Zones Found
                    </MenuItem>
                  )}
                </Select>
              }
            />
          </div>
        )}
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Show</TableCell>
                <TableCell>Colors</TableCell>
                <TableCell>Run Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {showList.map((show, i) => (
                <TableRow key={i}>
                  <TableCell width="1" padding="none">
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setDialogState({
                          ...dialogState,
                          showData: show,
                          row: i,
                        });
                      }}
                      style={{ padding: 0 }}
                      disabled={disabled || updating}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>{ShowNameMap[show.showName]}</TableCell>
                  <TableCell padding="none">
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        minWidth: '80px',
                      }}
                    >
                      {show.colors?.map((hex, i) => {
                        const color = mapColorValues([hex])[0];
                        return (
                          <div
                            key={i}
                            style={{
                              width: '16px',
                              height: '16px',
                              margin: '4px 8px',
                              padding: '3px',
                              borderRadius: '6px',
                              border: '1px solid',
                              backgroundColor: color.display,
                              borderColor: '#000000',
                              color: textColorFromBg(color.display),
                            }}
                          ></div>
                        );
                      })}
                    </div>
                  </TableCell>
                  <TableCell>
                    {show.duration &&
                      `${Math.floor(show.duration)} minutes ${Math.round(
                        60 * (show.duration % 1),
                      )} seconds`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {dialogState.row !== 0 && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                [showList[dialogState.row - 1], showList[dialogState.row]] = [
                  showList[dialogState.row],
                  showList[dialogState.row - 1],
                ];
                setShows(showList);
              }}
            >
              <Typography>Move Up</Typography>
            </MenuItem>
          )}
          {dialogState.row !== showList.length - 1 && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                [showList[dialogState.row + 1], showList[dialogState.row]] = [
                  showList[dialogState.row],
                  showList[dialogState.row + 1],
                ];
                setShows(showList);
              }}
            >
              <Typography>Move Down</Typography>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setDialogState({ ...dialogState, open: true });
            }}
          >
            <Typography>Edit</Typography>
          </MenuItem>
          <MenuItem
            color="error"
            onClick={() => {
              setAnchorEl(null);
              setShows(showList.filter((_, i) => i !== dialogState.row));
            }}
          >
            <Typography color="error">Delete</Typography>
          </MenuItem>
        </Menu>
        <div style={{ display: 'flex' }}>
          {showList.length < SHOW_NUMBER_LIMIT && (
            <Button
              variant="contained"
              color="primary"
              style={{ margin: '32px auto' }}
              onClick={() => {
                setDialogState({ open: true, showData: null, row: -1 });
              }}
              disabled={disabled || updating}
            >
              Add a Show
            </Button>
          )}
        </div>
        <Dialog fullScreen open={dialogState.open} onClose={closeDialog}>
          <ShowDialog
            closeDialog={closeDialog}
            showData={{ ...defaultShowConfiguration, ...dialogState.showData }}
            submitShow={(show) => {
              if (dialogState.showData) {
                setShows(
                  showList.map((oldShow, index) =>
                    dialogState.row === index ? show : oldShow,
                  ),
                );
              } else {
                setShows([...showList, show]);
              }
            }}
          />
        </Dialog>
      </Paper>
      <div
        style={{
          margin: '16px auto',
          display: 'flex',
          justifyContent: 'space-between',
          maxWidth: '500px',
        }}
      >
        {(userCon.state.currentOrg?.role === OrgRole.ORG_ADMIN ||
          userCon.state.user.role !== SysRole.SYSTEM_USER) &&
          !saveDisabled && (
            <Button
              variant="outlined"
              color="primary"
              disabled={disabled || updating}
              onClick={() => setShowSaveLineup(true)}
            >
              {'Save Lineup'}
            </Button>
          )}
        {((userCon.state.currentOrg?.org.orgLineups ?? []).length > 0 ||
          (systemLineups.data ?? []).length > 0) && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowLoadLineup(true)}
            disabled={disabled || updating}
          >
            Open Lineup
          </Button>
        )}
      </div>
      <SaveLineupModal
        lineupType={LineupType.MOOD_LIGHTING}
        open={showSaveLineup}
        onClose={() => {
          setShowSaveLineup(false);
          refreshUserContext(userCon.state, userCon.setState);
        }}
        lineup={showList}
      />
      <LoadLineupModal
        lineupType={LineupType.MOOD_LIGHTING}
        open={showLoadLineup}
        systemLineups={systemLineups.data ?? []}
        onClose={(lineup) => {
          setShowLoadLineup(false);
          if (lineup) setShows(lineup);
        }}
      />
    </div>
  );
}
