import {
  IconButton,
  Tooltip,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { FolderOpen, ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { GroupTreeNode } from '../orgTypes';
import { DeviceModel } from '../../devices/Devices';
import OrgGroupDeviceBox from './OrgGroupDeviceBox';
import OrgGroupBox from './OrgGroupBox';
import { findGroupTreeNodeByPath } from '../groupsHelpers';

interface OrgGroupsTabViewProps {
  groups: GroupTreeNode[];
  openSettings: Function;
  ungroupedDevices?: DeviceModel[];
  setEditGroup: Function;
  setEditPath: Function;
}

export default function OrgGroupsTabView({
  groups,
  openSettings,
  ungroupedDevices,
  setEditGroup,
  setEditPath,
}: OrgGroupsTabViewProps) {
  const [currentPath, setCurrentPath] = useState<string>(''); // Use path string instead of node
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  // Pagination
  const itemsPerPage = 8;

  // Function to get current group nodes based on the path
  const getCurrentGroups = (path: string): GroupTreeNode[] => {
    if (!path) return groups;
    const currentGroup = findGroupTreeNodeByPath(groups, path);
    return currentGroup ? currentGroup.children : [];
  };

  // Function to get devices for the current group
  const getDevicesForCurrentGroup = (): DeviceModel[] => {
    if (!currentPath) return ungroupedDevices || [];
    const currentGroup = findGroupTreeNodeByPath(groups, currentPath);
    return currentGroup && currentGroup.group.devices
      ? currentGroup.group.devices
      : [];
  };

  // Search logic for groups and devices
  const filteredGroups = getCurrentGroups(currentPath).filter((node) =>
    node.group.name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const filteredDevices = getDevicesForCurrentGroup().filter((device) =>
    device.name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  // Combine groups and devices for pagination
  const combinedItems = [...filteredGroups, ...filteredDevices];

  const totalPages = Math.ceil(combinedItems.length / itemsPerPage);

  // Paginated items based on current page and items per page
  const paginatedItems = combinedItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: '5px',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            flexWrap: 'wrap',
          }}
        >
          <IconButton
            style={{ width: '50px' }}
            disabled={!currentPath}
            onClick={() => {
              const newPath = currentPath.substring(
                0,
                currentPath.lastIndexOf('/'),
              );
              setCurrentPath(newPath);
              setEditPath(newPath);
              setSearchValue('');
              setCurrentPage(1);
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography
            color="textSecondary"
            variant="h3"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
            }}
          >
            <FolderOpen />
            Groups
          </Typography>

          {currentPath &&
            currentPath.split('/').map((part, index) => (
              <span key={index}>
                <Typography
                  color="textSecondary"
                  variant="h3"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '2px',
                  }}
                >
                  <FolderOpen />
                  {part}
                </Typography>
              </span>
            ))}
        </div>

        <div
          style={{
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
            marginBottom: '20px',
            marginTop: '5px',
            flexWrap: 'wrap',
          }}
        >
          <TextField
            inputRef={(input) => input && input.focus()}
            label="Search..."
            value={searchValue}
            style={{ width: '200px' }}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Can search based on name, location, and device ID">
                    <SearchIcon style={{ color: '#999999' }} />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '40px',
          marginTop: '5px',
        }}
      >
        {paginatedItems.map((item, key) => {
          if (Object.keys(item).includes('children')) {
            // Render group item
            const node = item as GroupTreeNode;
            return (
              <OrgGroupBox
                key={key}
                node={node}
                openSettings={() => {
                  openSettings();
                  setEditGroup(node.group);
                  setSearchValue('');
                }}
                setCurrentGroups={() => {
                  setCurrentPath(
                    currentPath
                      ? `${currentPath}/${node.group.name}`
                      : node.group.name,
                  );
                  setEditPath(
                    currentPath
                      ? `${currentPath}/${node.group.name}`
                      : node.group.name,
                  );
                }}
              />
            );
          } else {
            // Render device item
            const device = item as DeviceModel;
            return <OrgGroupDeviceBox key={key} device={device} />;
          }
        })}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '20px',
        }}
      >
        <IconButton
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => prev - 1)}
          size="small"
        >
          <ArrowBackIos fontSize="small" />
        </IconButton>
        <Typography
          style={{ margin: '0 10px', alignSelf: 'center' }}
          variant="body2"
        >
          Page {currentPage} of {totalPages} ({paginatedItems.length}/
          {combinedItems.length} items)
        </Typography>
        <IconButton
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => prev + 1)}
          size="small"
        >
          <ArrowForwardIos fontSize="small" />
        </IconButton>
      </div>
    </>
  );
}
