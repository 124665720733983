import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  MoreHoriz,
} from '@mui/icons-material';
import { useEffect, useState, useContext } from 'react';
import { CirclePicker } from 'react-color';
import { COLORS, WHITE, WHITE_VALUE } from '../Enums';
import { CustomColor } from '../orgs/orgTypes';
import { textColorFromBg } from '../orgs/helpers';
import { OrgColorContext } from '../context/OrgContext';
import { useLocation } from 'react-router-dom';

type props = {
  minColors: number;
  maxColors: number;
  value: string[] | number[][];
  onChange: (...event: any[]) => void;
  blockSubmit: (arg0: boolean) => void;
  outsideEvent: null | any;
};

export default function MultiColorPicker({
  minColors,
  maxColors,
  value,
  onChange,
  blockSubmit,
  outsideEvent,
}: props) {
  const theme = useTheme();
  const [pickerState, setPickerState] = useState<{
    open: boolean;
    index: number;
    color: number[];
    colorID?: string;
  }>({
    open: false,
    index: -1,
    color: [] as number[],
    colorID: '',
  });

  const location = useLocation();
  const showOrgColors = location.pathname !== '/admin';

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuIndex, setMenuIndex] = useState<number>(0);

  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuIndex(0);
  };

  const {
    customColorList: orgColorList,
    isLoading,
    mapColorValues,
  } = useContext(OrgColorContext);
  const data = mapColorValues(value);

  useEffect(() => {
    if (value.length > maxColors) {
      onChange(
        value.slice(0, maxColors).map((color: string | number[]) => color),
      );
    }
    if (value.length < minColors) {
      let t = [...value.map((color: string | number[]) => color)];
      for (let i = 0; i < minColors - t.length; i++) {
        t.push([...WHITE_VALUE]);
      }
      onChange(t);
    }
  }, [maxColors, minColors]);

  useEffect(() => {
    blockSubmit(pickerState.open);
  }, [pickerState.open]);

  useEffect(() => {
    if (outsideEvent && pickerState.open) {
      setPickerState({ ...pickerState, open: false });
    }
  }, [outsideEvent]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ marginTop: theme.spacing(1) }}>
      {data.length > 0 && (
        <Typography
          variant="h3"
          style={{ fontWeight: 400, margin: '16px 0 8px 0' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <div style={{ flex: 1 }}>Colors:</div>
            {data.length < maxColors && (
              <Button
                variant="text"
                onClick={() =>
                  onChange([
                    ...value.map((color: string | number[]) => color),
                    WHITE_VALUE,
                  ])
                }
                style={{ marginLeft: 'auto', fontSize: '30px', padding: '0px' }}
              >
                +
              </Button>
            )}
          </div>
        </Typography>
      )}
      <Table>
        <TableBody>
          {data.map((color, index) => (
            <TableRow
              key={index}
              style={{
                backgroundColor: color.display,
                border: '1px solid',
                borderColor: '#171725',
              }}
            >
              <TableCell
                style={{
                  position: 'relative',
                  color: textColorFromBg(color.display),
                  borderColor: '#171725',
                }}
                onClick={(e) => {
                  if (!pickerState.open) {
                    setPickerState({
                      open: true,
                      index,
                      color: color.value,
                      colorID: color._id,
                    });
                    e.stopPropagation();
                  }
                }}
              >
                {color.name}

                {pickerState.open && pickerState.index === index && (
                  <Paper
                    sx={{
                      position: 'absolute',
                      top: '75%',
                      left: 0,
                      zIndex: 9,
                      padding: theme.spacing(2),
                      borderRadius: '16px',
                      backgroundColor: '#d4d4d4',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onKeyDown={(e) => {
                      // stop form submit and close picker
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        setPickerState({ ...pickerState, open: false });
                      }
                    }}
                  >
                    <CirclePicker
                      color={mapColorValues([pickerState.color])[0].display}
                      colors={COLORS.map((color) => color.display)}
                      onChangeComplete={(pick, e) => {
                        const pickedValue =
                          COLORS.find(
                            (c) =>
                              c.display.toLowerCase() ===
                              pick.hex.toLowerCase(),
                          ) ?? WHITE;
                        onChange(
                          value.map((color: string | number[], i: number) =>
                            i === index ? pickedValue.value : color,
                          ),
                        );
                        setPickerState({
                          open: false,
                          index,
                          color: pickedValue.value,
                          colorID: pickedValue._id,
                        });
                      }}
                    />
                    {orgColorList.length > 0 && showOrgColors && (
                      <>
                        <Typography variant="h3">
                          ----Organization Colors----
                        </Typography>
                        <CirclePicker
                          color={
                            mapColorValues(
                              pickerState.colorID
                                ? [pickerState.colorID]
                                : [WHITE.value],
                            )[0]?.display
                          }
                          colors={orgColorList.map(
                            (color: CustomColor) => color.display,
                          )}
                          onChangeComplete={(pick, e) => {
                            const pickedValue =
                              orgColorList.find(
                                (c) =>
                                  c.display.toLowerCase() ===
                                  pick.hex.toLowerCase(),
                              ) ?? WHITE;
                            onChange(
                              value.map((color: string | number[], i: number) =>
                                i === index ? pickedValue._id : color,
                              ),
                            );
                            setPickerState({
                              open: false,
                              index,
                              color: [pick.rgb.r, pick.rgb.g, pick.rgb.b],
                              colorID: '',
                            });
                          }}
                        />
                      </>
                    )}
                  </Paper>
                )}
              </TableCell>
              <TableCell
                align="right"
                style={{
                  width: '24px',
                  borderColor: '#171725',
                }}
              >
                {index >= 0 && data.length > 1 && (
                  <>
                    <IconButton
                      style={{
                        padding: '0',
                        margin: '0 4px 0 auto',
                        fontSize: '36px',
                        color: textColorFromBg(color.display),
                      }}
                      onClick={(e) => handleClickMenu(e, index)}
                    >
                      <MoreHoriz />
                    </IconButton>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem
              style={{ justifyContent: 'space-between' }}
              onClick={() => {
                [value[menuIndex], value[menuIndex - 1]] = [
                  value[menuIndex - 1],
                  value[menuIndex],
                ];
                onChange(value.map((color: string | number[]) => color));
                handleCloseMenu();
              }}
              disabled={menuIndex === 0}
            >
              Move Up
              <ArrowUpwardOutlined />
            </MenuItem>
            <MenuItem
              style={{ justifyContent: 'space-between', gap: '8px' }}
              onClick={() => {
                [value[menuIndex], value[menuIndex + 1]] = [
                  value[menuIndex + 1],
                  value[menuIndex],
                ];
                onChange(value.map((color: string | number[]) => color));
                handleCloseMenu();
              }}
              disabled={menuIndex === data.length - 1}
            >
              Move Down
              <ArrowDownwardOutlined />
            </MenuItem>
            <MenuItem
              style={{ justifyContent: 'space-between' }}
              onClick={() => {
                onChange([
                  ...value.slice(0, menuIndex), // Get all elements before the removed index
                  ...value.slice(menuIndex + 1), // Get all elements after the removed index
                ]);
                handleCloseMenu();
              }}
              disabled={data.length <= minColors}
            >
              Delete
            </MenuItem>
          </Menu>
        </TableBody>
      </Table>
    </div>
  );
}
