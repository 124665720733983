import { Icon, IconButton, Paper, Typography } from '@mui/material';
import { Settings } from '@mui/icons-material';
import HighlightIcon from '@mui/icons-material/Highlight';
import { DeviceModel } from '../../devices/Devices';
import OrgGroupDeviceEditModal from '../OrgGroupDeviceEditModal';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../context/UserStateManager';

interface OrgGroupDeviceBoxProps {
  device: DeviceModel;
}
export default function OrgGroupDeviceBox({ device }: OrgGroupDeviceBoxProps) {
  const [isModalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const userCon = useContext(UserContext);

  return (
    <>
      <Paper
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '150px',
          height: '150px',
        }}
      >
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', height: '10%' }}
        >
          <IconButton onClick={() => setModalOpen(true)}>
            <Settings />
          </IconButton>
        </div>
        <div
          style={{
            cursor: 'pointer',
            height: '90%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: '12px',
          }}
          onClick={() => {
            userCon.setState({
              ...userCon.state,
              currentDevice: device,
            });

            history.push('/controller/' + device?._id || '');
          }}
        >
          <Icon color="primary" style={{ fontSize: '80px' }}>
            <HighlightIcon style={{ fontSize: '80px' }} />
          </Icon>

          <Typography variant="h3">{device.name}</Typography>
        </div>
      </Paper>

      <OrgGroupDeviceEditModal
        open={isModalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        device={device}
      />
    </>
  );
}
