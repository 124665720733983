import {
  AppBar,
  Button,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState, MouseEvent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DeviceSettings, {
  TriggerSettings,
} from '../devices/DeviceSettingsObject';
import { ConditionType } from '../Enums';
import ConditionSelection from './ConditionSelection';
import ShowForm, { trimShowFields } from './ShowForm';

type props = {
  closeDialog: () => void;
  triggerData: TriggerSettings;
  submit: (show: TriggerSettings) => void;
  configuration: DeviceSettings;
};

export default function TriggerForm({
  closeDialog,
  triggerData,
  submit,
  configuration,
}: props) {
  const theme = useTheme();
  const [outsideEvent, setOutsideEvent] = useState<null | MouseEvent>(null);
  const [blockSubmit, setBlockSubmit] = useState(false);
  const formObject = useForm<TriggerSettings>({
    defaultValues: triggerData,
  });

  return (
    <div
      style={{ minHeight: '100%', backgroundColor: '#F4F7FC' }}
      onClick={(e) => setOutsideEvent(e)}
    >
      <AppBar
        color="secondary"
        sx={{ height: `68px`, borderBottom: '1px solid #e0e0e0' }}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            aria-label="go back"
            edge="start"
            onClick={() => closeDialog()}
          >
            <ArrowBackIcon style={{ fontSize: '3rem', color: 'black' }} />
          </IconButton>
          <Typography variant="h1">Edit Input</Typography>
        </Toolbar>
      </AppBar>
      <div style={{ height: '68px' }} />
      <div
        style={{
          margin: 'auto',
          marginTop: theme.spacing(2),
          maxWidth: `min(500px, calc(100% - ${
            parseInt(theme.spacing(2)) * 2
          }px))`,
        }}
      >
        <Paper>
          <form
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            onSubmit={(e) => {
              if (blockSubmit) e.preventDefault();
              else
                formObject.handleSubmit((trigger) => {
                  trimShowFields(trigger.show, true);
                  submit(trigger);
                  closeDialog();
                })(e);
            }}
          >
            <Controller
              name="name"
              control={formObject.control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="Name:"
                  labelPlacement="start"
                  control={
                    <TextField
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  }
                />
              )}
              rules={{
                required: 'Required',
                maxLength: {
                  value: 50,
                  message: 'Must be less than 50 characters',
                },
              }}
            />
            <Controller
              name="zone"
              control={formObject.control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="Zone:"
                  labelPlacement="start"
                  control={
                    <Select value={value} onChange={onChange}>
                      {Array.isArray(configuration.settings.zones) &&
                      configuration.settings.zones[0].name ? (
                        configuration.settings.zones.map((zone, index) => (
                          <MenuItem key={index} value={index}>
                            {zone.name ?? 'Missing Name'}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key={0} value={0}>
                          No Zones Found
                        </MenuItem>
                      )}
                    </Select>
                  }
                />
              )}
            />
            <ConditionSelection<TriggerSettings>
              formObject={formObject}
              defaultVal={{
                conditionType: ConditionType.INPUT_CONDITION,
                condition: triggerData.condition,
              }}
              basePath=""
              trigger
            />
            <ShowForm<TriggerSettings>
              control={formObject.control}
              setBlockSubmit={setBlockSubmit}
              outsideEvent={outsideEvent}
              basePath="show."
              showData={triggerData.show}
              noDuration
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                marginTop: theme.spacing(2),
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              Submit
            </Button>
          </form>
        </Paper>
      </div>
    </div>
  );
}
