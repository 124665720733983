import {
  Button,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  createRef,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../context/UserStateManager';
import { DeviceSettingsContext } from './DevicePage';
import { Controller, useForm } from 'react-hook-form';
import ConfirmationModal from '../common/ConfirmationModal';
import { ControllerTypeMap, LogType, SysRole } from '../Enums';
import { fetchLogoutOn401 } from '../common/Handle401Fetch';
import { DeviceModel, GeoPosition } from '../devices/Devices';
import StatusCodeResponse from '../common/StatusCodeResponse';
import DeviceSettings from '../devices/DeviceSettingsObject';
import { ZoneTable } from '../common/ZoneTable';
import { PixelMapTable } from '../common/PixelMapTable';
import JSONValidator from './JSONValidator';
import DeviceLog from '../devices/DeviceLog';
import { toast, ToastContainer } from 'react-toastify';
import OrgGroupDeviceEditModal from '../orgs/OrgGroupDeviceEditModal';
import { getGroupById } from '../orgs/groupsHelpers';

type props = {
  updateDevice: Function;
};

export default function AdvancedSettingsForm({ updateDevice }: props) {
  const history = useHistory();
  const theme = useTheme();
  const userCon = useContext(UserContext);
  var zipcodes = require('zipcodes');

  const { configuration, setConfiguration, updating } = useContext(
    DeviceSettingsContext,
  );
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [settingsModalAccepted, setSettingsModalAccepted] = useState(false);
  const [settingsUploadError, setSettingsUploadError] = useState('');
  const [deviceChanged, setDeviceChanged] = useState(false);
  const [advancedChanged, setAdvancedChanged] = useState(false);
  const [loggingChanged, setLoggingChanged] = useState(false);
  const [pixelOpen, setPixelOpen] = useState(false);
  const [zonesOpen, setZonesOpen] = useState(false);
  const [infoResponse, setInfoResponse] = useState(0);
  const [logLevelStatus, setLogLevelStatus] = useState(0);

  const deviceForm = useForm<{
    name: string;
    location: string;
    zipCode: string;
    org: string | undefined;
    geoPosition: GeoPosition;
  }>({
    mode: 'onChange',
  });
  const advancedSettingsForm = useForm<{
    triggerCount: number;
    pixelMap: string;
    zones: string;
    customJson: JSON | [JSON];
  }>();
  const loggingLevelForm = useForm<{
    loggingLevel: number;
    loggingTimeoutDuration: number;
  }>({ mode: 'onChange' });
  const [uploadedSettings, setUploadedSettings] = useState<DeviceSettings>();
  const uploadRef: RefObject<HTMLInputElement> = createRef();
  const [formState, setFormState] = useState<{
    open: boolean;
    deviceObjID: null | string;
    logType: null | LogType;
  }>({ open: false, deviceObjID: null, logType: null });

  const [editGroupModalOpen, setEditGroupModalOpen] = useState(false);

  const groupName = useMemo(() => {
    return (
      (userCon.state.currentOrgGroupPaths ?? []).find((ele) =>
        ele.endsWith(
          getGroupById(
            userCon.state.currentDevice?.group || '',
            userCon.state.currentOrgGroupTree || [],
          )?.name || 'Unassigned',
        ),
      ) || 'Unassigned'
    );
  }, [
    userCon.state.currentDevice?.group,
    userCon.state.currentOrgGroupPaths,
    userCon.state.currentOrgGroupTree,
  ]);

  const downloadSettings = () => {
    const deviceId = userCon.state.currentDevice?.deviceID;
    const json = JSON.stringify(configuration, null, 2);
    const blob = new Blob([json], { type: 'text/json;' });
    const url = URL.createObjectURL(blob);
    const dl = document.createElement('a');
    dl.href = url;
    dl.setAttribute('download', `${deviceId}_${new Date().toISOString()}.json`);
    dl.click();
    dl.remove();
  };

  const removeDevice = async () => {
    const dto = {};
    const status = await updateDevice(dto, userCon.state.currentDevice?._id);

    if (status !== 200) {
      return;
    }

    history.push('/controller');
  };

  useEffect(() => {
    if (
      settingsModalAccepted &&
      !settingsModalOpen &&
      !uploadedSettings &&
      !settingsUploadError
    )
      uploadRef.current?.click();
  }, [
    settingsModalAccepted,
    settingsModalOpen,
    uploadRef,
    uploadedSettings,
    settingsUploadError,
  ]);
  return (
    <>
      <div>
        <Paper style={{ marginBottom: 16 }}>
          <Typography variant="h3">Controller Information</Typography>
          <Divider sx={{ marginTop: '8px', backgroundColor: '#707070' }} />
          <form
            onChange={(e) => setDeviceChanged(true)}
            onSubmit={deviceForm.handleSubmit(async (formData) => {
              //Have to set the org or else our function makes it null;
              formData.org = userCon.state.currentDevice?.org;

              formData.geoPosition = await zipcodes.lookup(formData.zipCode);
              if (!formData.geoPosition) {
                deviceForm.setError('zipCode', {
                  type: 'custom',
                  message: 'Invalid Zip Code!',
                });
                return;
              }

              const res = await fetchLogoutOn401(
                userCon,
                process.env.REACT_APP_BACKEND_URL +
                  '/devices/' +
                  userCon.state.currentDevice?._id,
                {
                  method: 'PATCH',
                  headers: {
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + userCon.state.jwtToken,
                  },
                  body: JSON.stringify(formData),
                },
              );
              if (res.ok) {
                userCon.setState({
                  ...userCon.state,
                  currentDevice: {
                    ...(userCon.state.currentDevice as DeviceModel),
                    name: formData.name,
                    location: formData.location,
                  },
                });

                setDeviceChanged(false);
                setConfiguration({
                  ...configuration,
                  geoPosition: {
                    ...formData.geoPosition,
                    latitude: String(formData.geoPosition.latitude),
                    longitude: String(formData.geoPosition.longitude),
                  },
                });
                toast.success('Saved Changes!');
                setInfoResponse(0);
              } else {
                setInfoResponse(999);
              }
            })}
          >
            <Controller
              name="name"
              control={deviceForm.control}
              defaultValue={userCon.state.currentDevice?.name ?? ''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="Name"
                  labelPlacement="start"
                  control={
                    <TextField
                      disabled={updating}
                      value={value}
                      onChange={(e) => {
                        setDeviceChanged(true);
                        onChange(e);
                      }}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  }
                />
              )}
              rules={{
                required: 'Required',
                maxLength: {
                  value: 50,
                  message: 'Must be less than 50 characters',
                },
              }}
            />
            <Controller
              name="location"
              control={deviceForm.control}
              defaultValue={userCon.state.currentDevice?.location ?? ''}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="Location"
                  labelPlacement="start"
                  control={
                    <TextField
                      disabled={updating}
                      value={value}
                      onChange={(e) => {
                        setDeviceChanged(true);
                        onChange(e);
                      }}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  }
                />
              )}
              rules={{
                maxLength: {
                  value: 50,
                  message: 'Must be less than 50 characters',
                },
              }}
            />
            <Controller
              name="zipCode"
              control={deviceForm.control}
              defaultValue={
                userCon.state.currentDevice?.configuration.geoPosition.zip ?? ''
              }
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  label="Zip Code"
                  labelPlacement="start"
                  control={
                    <TextField
                      disabled={updating}
                      value={value}
                      onChange={(e) => {
                        setDeviceChanged(true);
                        onChange(e);
                      }}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  }
                />
              )}
              rules={{
                maxLength: {
                  value: 7,
                  message: 'Must be less than 8 characters',
                },
                minLength: {
                  value: 5,
                  message: 'Must be at least 5 characters',
                },
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '10px',
                marginTop: '10px',
              }}
            >
              <Typography>Device Group</Typography>
              <Button
                size={'small'}
                style={{ textTransform: 'none' }}
                variant="outlined"
                color="primary"
                onClick={() => setEditGroupModalOpen(true)}
              >
                {groupName.split('/').join(' / ')}
              </Button>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '10px',
              }}
            >
              <Typography>DeviceID</Typography>
              <Typography>
                {userCon.state.currentDevice?.deviceID ?? ''}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '7px',
              }}
            >
              <Typography>Device Type</Typography>
              <Typography>
                {userCon.state.currentDevice?.configuration.controllerType &&
                  ControllerTypeMap[
                    userCon.state.currentDevice?.configuration.controllerType
                  ]}
              </Typography>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography>Debug Log</Typography>

              <Button
                size={'small'}
                variant="outlined"
                color="primary"
                style={{ textTransform: 'none' }}
                onClick={() => {
                  setFormState({
                    ...formState,
                    open: true,
                    logType: LogType.DEBUG,
                  });
                }}
              >
                <Typography>View Debug Log</Typography>
              </Button>
            </div>
            {deviceChanged && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '24px',
                }}
              >
                <Button
                  disabled={updating}
                  sx={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: theme.spacing(2),
                  }}
                  color="primary"
                  type="submit"
                  variant="contained"
                >
                  Save Settings
                </Button>
              </div>
            )}
            <StatusCodeResponse
              statusCode={infoResponse}
              codeResponses={[
                {
                  statusCode: 999,
                  response:
                    'An error occured, unable to save controller information.',
                },
              ]}
            />
          </form>
        </Paper>
        {userCon.state.user.role !== SysRole.SYSTEM_USER && (
          <>
            <Paper style={{ marginBottom: 16 }}>
              <Typography variant="h3">Controller Setup</Typography>
              <Divider
                sx={{ marginTop: '8px', backgroundColor: '#707070' }}
                style={{ marginBottom: '16px' }}
              />
              <form
                onChange={(e) => {
                  setAdvancedChanged(true);
                }}
                onSubmit={advancedSettingsForm.handleSubmit((formData) => {
                  if (uploadedSettings) {
                    setConfiguration({
                      ...uploadedSettings,
                    });
                  } else {
                    setConfiguration({
                      ...configuration,
                      settings: {
                        ...configuration.settings,
                        triggerCount: formData.triggerCount,
                        pixelMap: JSON.parse(formData.pixelMap),
                        zones: JSON.parse(formData.zones),
                        customJson: JSON.stringify(formData.customJson),
                      },
                    });
                    advancedSettingsForm.reset({
                      triggerCount: formData.triggerCount,
                      pixelMap: formData.pixelMap,
                      zones: formData.zones,
                      customJson: formData.customJson,
                    });
                  }
                  setAdvancedChanged(false);
                })}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={2}>
                    <Typography variant="h3">Trigger Count</Typography>
                    <Controller
                      name="triggerCount"
                      defaultValue={configuration.settings.triggerCount}
                      control={advancedSettingsForm.control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          disabled={updating}
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                            setAdvancedChanged(true);
                          }}
                        >
                          {[0, 8, 16, 24, 32].map((val) => (
                            <MenuItem key={val} value={val}>
                              {`${val} inputs`}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h3">Zones</Typography>
                    <Controller
                      name="zones"
                      defaultValue={JSON.stringify(
                        configuration.settings.zones,
                      )}
                      control={advancedSettingsForm.control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <div>
                          <Modal
                            style={{ display: 'flex', flexDirection: 'column' }}
                            open={zonesOpen}
                            onClose={() => setZonesOpen(false)}
                          >
                            <Paper
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                margin: '10vh auto',
                                width: '80%',
                                maxHeight: '80%',
                                overflowY: 'auto',
                                gap: '10px',
                              }}
                            >
                              {configuration.settings.zones.map((zone, i) => (
                                <Paper key={i}>
                                  <Typography variant="h1">
                                    {zone.name}
                                  </Typography>
                                  <div
                                    style={{
                                      minHeight: '200px',
                                      overflowX: 'auto',
                                      margin: theme.spacing(2),
                                    }}
                                  >
                                    <ZoneTable pixels={zone.pixels} />
                                  </div>
                                </Paper>
                              ))}
                              <Button
                                sx={{
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                  marginBottom: theme.spacing(2),
                                }}
                                style={{
                                  position: 'sticky',
                                  alignSelf: 'flex-end',
                                  bottom: '0',
                                }}
                                color="primary"
                                component="label"
                                variant="contained"
                                onClick={() => setZonesOpen(false)}
                              >
                                Close
                              </Button>
                            </Paper>
                          </Modal>
                          <Button
                            disabled={updating}
                            sx={{
                              marginLeft: 'auto',
                              marginRight: 'auto',
                              marginBottom: theme.spacing(2),
                            }}
                            color="secondary"
                            component="label"
                            variant="contained"
                            onClick={() => setZonesOpen(true)}
                          >
                            View Current Zones
                          </Button>
                          <br />
                          <Button
                            disabled={updating}
                            sx={{
                              marginLeft: 'auto',
                              marginRight: 'auto',
                              marginBottom: theme.spacing(2),
                            }}
                            component="label"
                            color="primary"
                            variant="contained"
                          >
                            Upload Zones
                            <input
                              disabled={updating}
                              type="file"
                              hidden
                              onChange={(e) => {
                                const files = e.target.files;
                                if (files && files?.length > 0) {
                                  let reader = new FileReader();
                                  reader.onload = (event) => {
                                    onChange(event?.target?.result as string);
                                  };
                                  reader.readAsText(files[0]);
                                }
                              }}
                            />
                          </Button>
                          {!!error && (
                            <Typography variant="h3" color="error">
                              {error.message}
                            </Typography>
                          )}
                        </div>
                      )}
                      rules={{
                        validate: (value) => {
                          try {
                            JSON.parse(value);
                          } catch (e) {
                            return 'File must be valid JSON.';
                          }
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h3">Pixel Map</Typography>
                    <Controller
                      name="pixelMap"
                      defaultValue={JSON.stringify(
                        configuration.settings.pixelMap,
                      )}
                      control={advancedSettingsForm.control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <div>
                          <Modal
                            open={pixelOpen}
                            style={{ display: 'flex', flexDirection: 'column' }}
                            onClose={() => setPixelOpen(false)}
                          >
                            <Paper
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                margin: '10vh auto',
                                width: '80%',
                                maxHeight: '80%',
                                overflowY: 'auto',
                                gap: '10px',
                              }}
                            >
                              <Paper
                                elevation={3}
                                sx={{
                                  minHeight: '200px',
                                  overflowX: 'auto',
                                  margin: theme.spacing(2),
                                }}
                              >
                                <PixelMapTable
                                  pixelMap={configuration.settings.pixelMap}
                                />
                              </Paper>
                              <Button
                                sx={{
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                  marginBottom: theme.spacing(2),
                                }}
                                color="primary"
                                component="label"
                                variant="contained"
                                onClick={() => setPixelOpen(false)}
                              >
                                Close
                              </Button>
                            </Paper>
                          </Modal>
                          <Button
                            disabled={updating}
                            sx={{
                              marginLeft: 'auto',
                              marginRight: 'auto',
                              marginBottom: theme.spacing(2),
                            }}
                            color="secondary"
                            component="label"
                            variant="contained"
                            onClick={() => setPixelOpen(true)}
                          >
                            View Current Pixel Map
                          </Button>
                          <br />
                          <Button
                            disabled={updating}
                            sx={{
                              marginLeft: 'auto',
                              marginRight: 'auto',
                              marginBottom: theme.spacing(2),
                            }}
                            color="primary"
                            component="label"
                            variant="contained"
                          >
                            Upload Pixel Map
                            <input
                              disabled={updating}
                              type="file"
                              hidden
                              onChange={(e) => {
                                const files = e.target.files;
                                if (files && files?.length > 0) {
                                  let reader = new FileReader();
                                  reader.onload = (event) => {
                                    onChange(event?.target?.result as string);
                                  };
                                  reader.readAsText(files[0]);
                                }
                              }}
                            />
                          </Button>
                          {!!error && (
                            <Typography variant="h3" color="error">
                              {error.message}
                            </Typography>
                          )}
                        </div>
                      )}
                      rules={{
                        validate: (value) => {
                          try {
                            JSON.parse(value);
                          } catch (e) {
                            return 'File must be valid JSON.';
                          }
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="h3">Device Settings</Typography>
                    <Button
                      disabled={updating}
                      sx={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: theme.spacing(2),
                      }}
                      color="secondary"
                      component="label"
                      variant="contained"
                      onClick={downloadSettings}
                    >
                      Download Settings
                    </Button>
                    <Button
                      disabled={updating}
                      sx={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: theme.spacing(2),
                      }}
                      color="primary"
                      component="label"
                      variant="contained"
                      onClick={() => {
                        if (!settingsModalAccepted) {
                          setSettingsModalOpen(true);
                          setSettingsUploadError('');
                        }
                      }}
                    >
                      Upload Device Settings
                      {settingsModalAccepted && (
                        <input
                          disabled={updating}
                          type="file"
                          ref={uploadRef}
                          hidden
                          onChange={(e) => {
                            const files = e.target.files;
                            if (files && files?.length > 0) {
                              let reader = new FileReader();
                              reader.onload = (event) => {
                                try {
                                  const settings = JSON.parse(
                                    event?.target?.result as string,
                                  );
                                  setSettingsUploadError('');
                                  setUploadedSettings(settings);
                                } catch (e) {
                                  setSettingsUploadError(
                                    'File must be valid JSON.',
                                  );
                                }
                              };
                              reader.readAsText(files[0]);
                            }
                          }}
                        />
                      )}
                    </Button>
                    {settingsUploadError && (
                      <Typography style={{ color: 'red' }}>
                        {settingsUploadError}
                      </Typography>
                    )}
                    <ConfirmationModal
                      title="Upload Device Settings"
                      message="This will overwrite this Device's settings. File contents are NOT validated."
                      confirmText="Continue"
                      onClose={() => setSettingsModalOpen(false)}
                      onConfirm={() => {
                        setSettingsModalAccepted(true);
                        setSettingsModalOpen(false);
                      }}
                      open={settingsModalOpen}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      name="customJson"
                      control={advancedSettingsForm.control}
                      defaultValue={configuration.settings.customJson}
                      render={({ field: { onChange, value } }) => (
                        <JSONValidator
                          onChange={onChange}
                          customValue={value}
                          setError={advancedSettingsForm.setError}
                          clearError={advancedSettingsForm.clearErrors}
                        />
                      )}
                    />
                  </Grid>
                  {advancedChanged && !settingsUploadError && (
                    <Button
                      disabled={
                        updating ||
                        Object.keys(advancedSettingsForm.formState.errors)
                          .length > 0
                      }
                      sx={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: theme.spacing(2),
                      }}
                      color="primary"
                      type="submit"
                      variant="contained"
                    >
                      Save Settings
                    </Button>
                  )}
                </Grid>
              </form>
            </Paper>

            <Paper style={{ marginBottom: 16 }}>
              <div>
                <Typography variant="h3">Device Logging Level</Typography>
                <form
                  onChange={(e) => {
                    setLoggingChanged(true);
                  }}
                  onSubmit={loggingLevelForm.handleSubmit(async (formData) => {
                    try {
                      const res = await fetchLogoutOn401(
                        userCon,
                        `${process.env.REACT_APP_BACKEND_URL}/devices/${userCon.state?.currentDevice?._id}/setloglevel`,
                        {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + userCon.state.jwtToken,
                          },
                          body: JSON.stringify({
                            level: formData.loggingLevel,
                            timeOut: Number(formData.loggingTimeoutDuration),
                          }),
                        },
                      );
                      setLogLevelStatus(res.status);
                      setLoggingChanged(false);
                    } catch (e) {
                      setLogLevelStatus(500);
                    }
                  })}
                >
                  <Controller
                    name="loggingLevel"
                    control={loggingLevelForm.control}
                    defaultValue={0}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormControlLabel
                        style={{
                          width: 'fit-content',
                          marginBottom: '10px',
                          gap: '10px',
                        }}
                        label="Logging Level: "
                        labelPlacement="start"
                        control={
                          <Select
                            disabled={updating}
                            value={value}
                            onChange={(e) => {
                              setLoggingChanged(true);
                              setLogLevelStatus(0);
                              onChange(e);
                            }}
                          >
                            {[0, 1, 2, 3, 4, 5].map((val) => (
                              <MenuItem
                                key={val}
                                value={val}
                              >{`Level ${val}`}</MenuItem>
                            ))}
                          </Select>
                        }
                      />
                    )}
                  />

                  <Controller
                    name="loggingTimeoutDuration"
                    control={loggingLevelForm.control}
                    defaultValue={180}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormControlLabel
                        style={{
                          width: 'fit-content',
                          gap: '10px',
                        }}
                        label="Timeout Duration: "
                        labelPlacement="start"
                        control={
                          <TextField
                            value={value}
                            inputProps={{ type: 'number' }}
                            style={{ width: '90px' }}
                            error={!!error}
                            helperText={error ? error.message : null}
                            onChange={(e) => {
                              onChange(e);
                              setLoggingChanged(true);
                            }}
                          />
                        }
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: 'You need to input a Timeout Duration.',
                      },
                      min: {
                        value: 0,
                        message: 'Cannot be less than 0 seconds',
                      },
                      max: {
                        value: 1000,
                        message: 'Cannot be greater than 1000 seconds',
                      },
                    }}
                  />

                  {loggingChanged && (
                    <Button
                      disabled={updating || !loggingLevelForm.formState.isValid}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Set Logging Level
                    </Button>
                  )}
                </form>

                {logLevelStatus === 200 && (
                  <Typography
                    style={{ marginTop: '10px', color: 'green' }}
                    variant="subtitle1"
                  >{`Logging level was set to ${
                    loggingLevelForm.getValues().loggingLevel
                  }, and the timeout was set to ${
                    loggingLevelForm.getValues().loggingTimeoutDuration
                  } seconds`}</Typography>
                )}
                {logLevelStatus > 200 && (
                  <Typography
                    style={{ marginTop: '10px', color: 'red' }}
                    variant="subtitle1"
                  >
                    An error occurred when changing the logging level
                  </Typography>
                )}
              </div>
            </Paper>

            <Paper>
              <div>
                <Typography variant="h3">Remove Controller</Typography>

                <Typography variant="h4" style={{ paddingTop: '16px' }}>
                  Remove this controller from this organization.
                </Typography>

                <Button
                  disabled={updating}
                  variant="contained"
                  style={{
                    margin: '16px auto 0px auto',
                    backgroundColor: '#F0142F',
                    color: '#ffffff',
                  }}
                  onClick={() => setRemoveModalOpen(true)}
                >
                  Remove Controller
                </Button>

                <ConfirmationModal
                  open={removeModalOpen}
                  onClose={() => setRemoveModalOpen(false)}
                  onConfirm={() => removeDevice()}
                  title="Remove Controller?"
                  message="Are you sure you want to remove this controller from this organization?"
                  confirmText="Remove"
                />
              </div>
            </Paper>
          </>
        )}

        <Dialog
          fullScreen
          open={formState.open && !!formState.logType}
          onClose={() =>
            setFormState({ open: false, deviceObjID: null, logType: null })
          }
        >
          <DeviceLog
            deviceData={userCon.state.currentDevice}
            closeDialog={() =>
              setFormState({
                open: false,
                deviceObjID: null,
                logType: null,
              })
            }
            logType={formState.logType}
          />
        </Dialog>

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>

      <OrgGroupDeviceEditModal
        open={editGroupModalOpen}
        onClose={() => {
          setEditGroupModalOpen(false);
        }}
        setUserState={userCon.setState}
        device={userCon.state.currentDevice!}
      />
    </>
  );
}
